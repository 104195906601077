import { Footer } from '@/layout/Footer';
import GlobalStyles, { theme } from '@/layout/GlobalStyles';
import { Instagram } from '@/layout/Instagram';
import { MapBookingForm } from '@/layout/MapBookingForm';
import { Nav } from '@/layout/Nav';
import { Testimonials } from '@/layout/Testimonials';
import { ThemeProvider } from '@emotion/react';
import { SkipNavContent } from '@reach/skip-nav';
import React from 'react';

type DefaultLayoutProps = {
    children: React.ReactNode;
};

export const DefaultLayout = ({ children }: DefaultLayoutProps) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Nav />
            <SkipNavContent />
            <main>{children}</main>
            <Instagram />
            <Testimonials />
            <MapBookingForm />
            <Footer />
        </ThemeProvider>
    );
};
