import styled from '@emotion/styled';
import { h3 } from './layout/GlobalStyles';

export const Input = styled.input<{ rows?: number; lightBg?: boolean }>`
    background: ${({ theme }) => theme.offWhite};
    border: none;
    color: ${({ theme }) => theme.black};
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    display: block;
    transition: outline-color 0.3s ease-in-out;
    outline: 1px solid transparent;
    outline-offset: 2px;

    :focus,
    :hover {
        outline-color: ${({ theme }) => theme.green};

        :invalid {
            outline-color: #e75842;
        }
    }

    ::placeholder {
        color: #737373;
    }
`;

export const Label = styled.label<{ lightBg?: boolean }>`
    color: ${({ theme, lightBg }) => (lightBg ? theme.black : theme.white)};
    margin-bottom: 18px;
    margin-top: 32px;
    display: inline-block;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
`;

export const RadioGroup = styled.div`
    @media (min-width: 640px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const RadioLabel = styled.span`
    margin-left: 8px;
    color: ${({ theme }) => theme.white};
`;

export const RadioLabelContainer = styled(Label)`
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 0;

    &:first-of-type {
        margin-top: 8px;
        margin-bottom: 16px;
    }

    &:last-of-type {
        margin-top: 16px;
        margin-bottom: 24px;
    }

    &:hover {
        cursor: pointer;
    }

    > span {
        display: flex;
        align-items: center;
    }

    @media (min-width: 640px) {
        margin: 8px 0 16px !important;
    }
`;

export const RadioControl = styled.span`
    display: grid;
    place-items: center;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.12em solid ${({ theme }) => theme.white};
`;

export const RadioInput = styled.input`
    opacity: 0;
    height: 0;
    width: 0;

    & + ${RadioControl}::before {
        content: '';
        width: 0.5em;
        height: 0.5em;
        background: ${({ theme }) => theme.white};
        border-radius: 50%;
        transition: 180ms transform ease-in-out;
        transform: scale(0);
    }
    &:focus + ${RadioControl} {
        box-shadow: 0 0 7px 0.05em #fff, 0 0 0.15em 0.1em #b2e6e3;
    }
    &:checked + ${RadioControl}::before {
        transform: scale(1);
    }
`;

export const Thanks = styled.div<{ submit: boolean; lightBg?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    color: ${({ theme, lightBg }) => (lightBg ? theme.darkGreen : theme.white)};
    background-color: ${({ theme, lightBg }) => (lightBg ? theme.white : theme.darkGreen)};

    > h1 {
        margin-top: 0;
    }

    > h5 {
        ${h3};
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        padding: 64px;
    }
`;
