import { NavLinkBtn, SubNavLink } from '@/layout/NavLinks';
import smClose from '@a/small-close.svg';
import styled from '@emotion/styled';
import useMountTransition from '@h/useMountTransition';
import useRouteChange from '@h/useRouteChange';
import useScrollBlock from '@h/useScrollBlock';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { Fragment, useEffect, useRef, useState } from 'react';
import { IconBtn } from './Buttons/IconBtn';

const StyledDialogOverlay = styled(DialogOverlay)`
    &[data-reach-dialog-overlay] {
        background: ${({ theme }) => theme.darkGreenOverlay};
        animation: dialog-overlay 0.2s ease-in-out;
        z-index: 1000;

        @keyframes dialog-overlay {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
`;

type StyledDialogContentProps = {
    $rows: number;
    $clientRect: {
        left: number | undefined;
        width: number | undefined;
    };
    $showDialog: boolean;
    $hasTransitionedIn: boolean;
};

const StyledDialogContent = styled(DialogContent, {
    shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<StyledDialogContentProps>`
    box-shadow: 0px -16px 32px rgba(255, 255, 255, 0.25), 0px 16px 64px rgba(0, 85, 80, 0.2);
    border-radius: 32px;
    display: grid;
    grid-template-rows: ${({ $rows }) => `repeat(${$rows}, 1fr)`};
    grid-auto-flow: column;
    gap: 32px 64px;
    position: relative;
    padding: 64px 64px 64px 32px !important;
    width: fit-content !important;
    margin: 90px 0 0 ${({ $clientRect }) => `${$clientRect.left}px`} !important;
    transform: ${({ $showDialog, $hasTransitionedIn, $clientRect }) =>
        $showDialog && $hasTransitionedIn
            ? `translate(calc(-50% + (${$clientRect.width}px / 2)), 0)`
            : `translate(calc(-50% + (${$clientRect.width}px / 2)), -100vh)`};
    opacity: ${({ $showDialog, $hasTransitionedIn }) =>
        $showDialog && $hasTransitionedIn ? 1 : 0};
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
`;

const StyledCloseBtn = styled(IconBtn)`
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
`;

type DialogProps = {
    text: string;
    links: {
        text: string;
        link: string;
    }[];
};

export const Dialog = ({ text, links }: DialogProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const toggleDialog = () => setShowDialog(show => !show);

    useScrollBlock(showDialog);

    const BtnRef = useRef<HTMLButtonElement>(null);
    const [clientRect, setClientRect] = useState<{
        left: number | undefined;
        width: number | undefined;
    }>({ left: 0, width: 0 });

    useEffect(() => {
        function CalcClientRect() {
            setClientRect({
                left: BtnRef?.current?.getBoundingClientRect()?.left,
                width: BtnRef?.current?.getBoundingClientRect()?.width,
            });
        }
        CalcClientRect();

        window.addEventListener('resize', CalcClientRect);

        return () => {
            window.removeEventListener('resize', CalcClientRect);
        };
    }, [BtnRef]);

    const hasTransitionedIn = useMountTransition(showDialog, 200);

    useRouteChange(setShowDialog);

    return (
        <Fragment>
            <NavLinkBtn
                onClick={toggleDialog}
                expanded={showDialog ? 'true' : 'false'}
                ref={BtnRef}
            >
                {text}
            </NavLinkBtn>

            {(showDialog || hasTransitionedIn) && (
                <StyledDialogOverlay onDismiss={toggleDialog} dangerouslyBypassScrollLock>
                    <StyledDialogContent
                        aria-label="sub navigation"
                        $rows={links.length > 5 && text !== 'about us' ? 5 : links.length}
                        $clientRect={clientRect}
                        $showDialog={showDialog}
                        $hasTransitionedIn={hasTransitionedIn}
                    >
                        <StyledCloseBtn onClick={toggleDialog}>
                            <img src={smClose} alt="close navigation dialog" />
                        </StyledCloseBtn>
                        {links.map((link, i) => (
                            <SubNavLink key={i} to={link.link}>
                                {link.text}
                            </SubNavLink>
                        ))}
                    </StyledDialogContent>
                </StyledDialogOverlay>
            )}
        </Fragment>
    );
};
