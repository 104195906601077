import styled from '@emotion/styled';
import { Link } from 'gatsby';

type Props = {
    href?: string;
    target?: string;
    rel?: string;
    to?: React.ComponentProps<typeof Link>['to'];
};

export const PlainBtn = styled.button<Props>`
    display: inline-block;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    font: inherit;
    line-height: 150%;
    cursor: pointer;
`;
