import { PlainBtn } from '@/Buttons/PlainBtn';
import { PrimarySmBtn } from '@/Buttons/PrimarySmBtn';
import close from '@a/layout/nav/close.svg';
import location from '@a/layout/nav/location.svg';

import menu from '@a/layout/nav/menu.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useRouteChange from '@h/useRouteChange';
import useScrollBlock from '@h/useScrollBlock';
import FocusTrap from 'focus-trap-react';
import { Link } from 'gatsby';
import { useRef, useState } from 'react';
import { Burnsville, MobileNavLinks } from './NavLinks';
import { StaticImage } from 'gatsby-plugin-image';

const NavSlider = styled.div<{ navOpen: boolean; navVisibility: boolean }>`
    position: fixed;
    top: 0;
    left: ${({ navOpen }) => (navOpen ? 0 : '100vw')};
    height: 100%;
    width: 100vw;
    z-index: 100;
    overflow-y: auto;
    background: ${({ theme }) => theme.gradient};
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    transition: left 0.3s ease-in-out;
    padding: 16px;
    padding-bottom: 32px;
    visibility: ${({ navVisibility }) => (navVisibility ? 'visible' : 'hidden')};
`;

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledLink = styled(Link)`
    transition: opacity 0.3s ease-in-out;

    :hover,
    :focus {
        opacity: 0.75;
    }
`;

const NavLinksContainer = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 74vh;
`;

const NavLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 50vh;
`;

const OpenBtn = styled(PlainBtn)`
    display: block;

    :hover,
    :focus {
        > img {
            filter: ${({ theme }) => theme.darkGreenFilter};
        }
    }
`;

const CloseBtn = styled(PlainBtn)`
    :hover,
    :focus {
        > img {
            filter: ${({ theme }) => theme.darkGreenFilter};
        }
    }
`;

export const MobNav = () => {
    const [navOpen, setNavOpen] = useState(false);
    const [navVisibility, setNavVisibility] = useState(false);

    const toggleNav = () => {
        if (navOpen) {
            setNavOpen(false);
            setTimeout(() => setNavVisibility(false), 300);
        } else {
            setNavVisibility(true);
            setTimeout(() => setNavOpen(true), 300);
        }
    };

    useScrollBlock(navOpen);

    const FocusRef = useRef(null);

    useRouteChange(setNavOpen);

    return (
        <div
            css={css`
                @media (min-width: 1024px) {
                    display: none;
                }
            `}
        >
            <OpenBtn onClick={toggleNav}>
                <img src={menu} alt="open navigation menu" width={64} height={49} />
            </OpenBtn>
            <FocusTrap
                active={navOpen}
                focusTrapOptions={{
                    initialFocus: false,
                    escapeDeactivates: false,
                }}
                ref={FocusRef}
            >
                <NavSlider navOpen={navOpen} navVisibility={navVisibility}>
                    <FlexBox>
                        <StyledLink to="/">
                            <StaticImage
                                src="../../../assets/images/layout/nav/logo.png"
                                alt="integrate institute"
                                width={275}
                                height={55}
                            />
                        </StyledLink>

                        <CloseBtn onClick={toggleNav}>
                            <img src={close} alt="close navigation menu" />
                        </CloseBtn>
                    </FlexBox>
                    <NavLinksContainer>
                        <NavLinks>{MobileNavLinks}</NavLinks>

                        <div>
                            <Burnsville
                                href="#map"
                                css={css`
                                    margin: 0 auto 16px;
                                `}
                            >
                                <img src={location} alt="" /> Burnsville
                            </Burnsville>
                            <PrimarySmBtn as="a" href="tel:+19523001461">
                                (952) 300 - 1461
                            </PrimarySmBtn>
                        </div>
                    </NavLinksContainer>
                </NavSlider>
            </FocusTrap>
        </div>
    );
};
