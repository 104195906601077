import styled from '@emotion/styled';
import { PlainBtn } from './PlainBtn';

export const IconBtn = styled(PlainBtn)`
    border-radius: 64px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.green};
    transition: background 0.3s ease-in-out;

    :hover,
    :focus-visible {
        background: ${({ theme }) => theme.darkGreen};
    }
`;
