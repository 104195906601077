import { PrimaryLgBtn } from '@/Buttons/PrimaryLgBtn';
import { h1 } from '@/layout/GlobalStyles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import {
    Input,
    Label,
    RadioControl,
    RadioGroup,
    RadioInput,
    RadioLabel,
    RadioLabelContainer,
    Thanks,
} from '@/FormComponents';

const BookingContainer = styled.div`
    background: ${({ theme }) => theme.darkGreen};
    border-top-right-radius: 64px;
    padding: 56px 32px;
    width: 100%;
    max-width: 800px;

    > h5 {
        ${h1};
        color: ${({ theme }) => theme.white};
        margin: 0 0 8px;
    }

    > p {
        color: ${({ theme }) => theme.white};
        margin: 0;
    }

    > form {
        position: relative;
    }

    #submit-btn {
        margin-top: 64px;
    }

    @media (min-width: 501px) {
        padding: 56px 64px;
    }

    @media (min-width: 640px) {
        padding: 64px 104px;
    }

    @media (min-width: 1280px) {
        width: 50%;

        #submit-btn {
            margin-left: auto;
            display: block;
        }
    }
`;

const Flex = styled.div`
    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            width: 50%;

            :first-of-type {
                margin-right: 16px;
            }
        }
    }
`;

export const BookingForm = () => {
    const [submit, setSubmit] = useState(false);

    const dateRef = useRef<HTMLInputElement | null>(null);

    const handleFocus = () => {
        if (dateRef.current) {
            dateRef.current.type = 'date';
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));
        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
    };

    return (
        <BookingContainer id="appointment">
            <h5>Book An Appointment</h5>
            <p>Please fill out the form and our team will get back to you within 24 Hours</p>
            <form onSubmit={handleSubmit}>
                <Flex>
                    <div>
                        <Label htmlFor="name">Full Name</Label>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Fill in your full name"
                        />
                    </div>
                    <div>
                        <Label htmlFor="phone">Phone*</Label>
                        <Input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Your phone number"
                            required
                        />
                    </div>
                </Flex>

                <Label htmlFor="email">Email*</Label>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email address here"
                    required
                />
                <Label as="p">Preferred time</Label>
                <RadioGroup>
                    <RadioLabelContainer htmlFor="morning">
                        <span>
                            <RadioInput type="radio" id="morning" name="time" value="morning" />
                            <RadioControl />
                        </span>
                        <RadioLabel>Morning</RadioLabel>
                    </RadioLabelContainer>
                    <RadioLabelContainer htmlFor="afternoon">
                        <span>
                            <RadioInput type="radio" id="afternoon" name="time" value="afternoon" />
                            <RadioControl />
                        </span>

                        <RadioLabel>Afternoon</RadioLabel>
                    </RadioLabelContainer>
                    <RadioLabelContainer htmlFor="evening">
                        <span>
                            <RadioInput type="radio" id="evening" name="time" value="evening" />
                            <RadioControl />
                        </span>

                        <RadioLabel>Evening</RadioLabel>
                    </RadioLabelContainer>
                </RadioGroup>
                <Label htmlFor="date">Date</Label>
                <Input
                    id="date"
                    name="date"
                    placeholder="dd/mm/yyyy"
                    ref={dateRef}
                    onFocus={handleFocus}
                    css={css`
                        height: 52.4px;
                        max-width: 280px;
                    `}
                />
                <Label htmlFor="message">Message</Label>
                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    placeholder="Write your message here"
                    rows={7}
                />
                <PrimaryLgBtn id="submit-btn">Send Message</PrimaryLgBtn>
                <Thanks submit={submit}>
                    <h1>Thank you</h1>
                    <h5>Someone will be right with you shortly!</h5>
                </Thanks>
            </form>
        </BookingContainer>
    );
};
