exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-dr-alex-twenge-tsx": () => import("./../../../src/pages/about-us/dr-alex-twenge.tsx" /* webpackChunkName: "component---src-pages-about-us-dr-alex-twenge-tsx" */),
  "component---src-pages-about-us-dr-derek-fruetel-tsx": () => import("./../../../src/pages/about-us/dr-derek-fruetel.tsx" /* webpackChunkName: "component---src-pages-about-us-dr-derek-fruetel-tsx" */),
  "component---src-pages-about-us-dr-gavin-manning-tsx": () => import("./../../../src/pages/about-us/dr-gavin-manning.tsx" /* webpackChunkName: "component---src-pages-about-us-dr-gavin-manning-tsx" */),
  "component---src-pages-about-us-faq-tsx": () => import("./../../../src/pages/about-us/faq.tsx" /* webpackChunkName: "component---src-pages-about-us-faq-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-megan-twenge-tsx": () => import("./../../../src/pages/about-us/megan-twenge.tsx" /* webpackChunkName: "component---src-pages-about-us-megan-twenge-tsx" */),
  "component---src-pages-about-us-recommended-products-tsx": () => import("./../../../src/pages/about-us/recommended-products.tsx" /* webpackChunkName: "component---src-pages-about-us-recommended-products-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

