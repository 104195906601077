import { DefaultLayout } from '@l/DefaultLayout';
import { SiteContextProvider } from '@l/SiteContextProvider';
import { cloneElement } from 'react';

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => {
    return <SiteContextProvider>{element}</SiteContextProvider>;
};

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
    if (element.type.Layout === false) {
        return cloneElement(element, props);
    }
    const Layout = element.type.Layout ?? DefaultLayout;
    return <Layout {...props}>{element}</Layout>;
};
