import { PrimarySmBtn } from '@/Buttons/PrimarySmBtn';
import location from '@a/layout/nav/location.svg';
import styled from '@emotion/styled';
import { SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import { Link } from 'gatsby';
import { px } from './GlobalStyles';
import { MobNav } from './MobNav';
import { DesktopNavLinks, Burnsville } from './NavLinks';
import logo from '../../../assets/images/layout/nav/logo.png';

const Header = styled.header`
    ${px};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const StyledLink = styled(Link)`
    transition: opacity 0.3s ease-in-out;

    :hover,
    :focus {
        opacity: 0.75;
    }
`;

const DesktopNav = styled.nav`
    display: none;

    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 320px);
        max-width: 660px;
    }

    @media (min-width: 1440px) {
        max-width: 700px;
    }
`;

const Buttons = styled.div`
    display: none;
    align-items: center;

    @media (min-width: 1366px) {
        display: flex;

        > a:first-of-type {
            display: none;
        }
    }

    @media (min-width: 1600px) {
        > a:first-of-type {
            display: flex;
        }
    }
`;

export const Nav = () => {
    return (
        <Header>
            <StyledLink to="/">
                <img src={logo} alt="integrate institute" width={275} height={55} loading="eager" />
            </StyledLink>
            <SkipNavLink />
            <MobNav />

            <DesktopNav>{DesktopNavLinks}</DesktopNav>

            <Buttons>
                <Burnsville href="#map">
                    <img src={location} alt="" /> Burnsville
                </Burnsville>
                <PrimarySmBtn as="a" href="tel:+19523001461">
                    (952) 300 - 1461
                </PrimarySmBtn>
            </Buttons>
        </Header>
    );
};
