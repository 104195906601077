import lgArrow from '@a/lg-arrow.svg';
import styled from '@emotion/styled';
import { PlainBtn } from './PlainBtn';

export const PrimaryLgBtn = styled(PlainBtn)`
    border-radius: 8px;
    background-color: ${({ theme }) => theme.green};
    padding: 16px 48px;
    color: ${({ theme }) => theme.white};
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: capitalize;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    transition-property: box-shadow, padding;
    position: relative;
    z-index: 1;

    /* background-image cant be animated so used before */
    ::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 8px;
        background-image: ${({ theme }) => theme.gradient};
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: -1;
    }

    ::after {
        content: '';
        position: absolute;
        right: 33px;
        top: calc((1em / 2) + 14px);
        width: 14px;
        height: 10px;
        background: url(${lgArrow}) no-repeat;
        filter: brightness(0) invert(1);
        opacity: 0;
        transform: translateX(10px);
        transition: opacity 0.1s ease-in-out, transform 0.3s ease-in-out;
    }

    :hover,
    :focus {
        box-shadow: ${({ theme }) => theme.boxShadow1};
        padding: 16px 64px 16px 32px;

        ::before {
            opacity: 1;
        }

        ::after {
            transform: translateX(0);
            opacity: 1;
        }
    }
`;
