import { PrimarySmBtn } from '@/Buttons/PrimarySmBtn';
import { customScrollbar2, h1 } from '@/layout/GlobalStyles';
import styled from '@emotion/styled';
import Masonry from 'react-masonry-css';
import video1 from '../../../assets/videos/seminar.mp4';
import video2 from '../../../assets/videos/opening.mp4';

const InstagramSection = styled.section`
    background-color: ${({ theme }) => theme.darkGreen};
    padding-top: 32px;
    padding-bottom: 32px;
    text-align: center;

    > h5 {
        ${h1};
        color: ${({ theme }) => theme.white};
        margin: 0;
    }

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

const Posts = styled(Masonry)`
    ${customScrollbar2};
    margin: 64px 0;

    .insta-masonry-grid_column {
        padding-left: 16px; /* gutter size */
        background-clip: padding-box;
        width: auto !important;

        :last-of-type {
            padding-right: 16px;
        }

        video {
            margin: 0;
            width: 320px;
            height: 568px;
            display: block;
            flex-shrink: 0;
            border-radius: 16px;
            overflow: hidden;
            background-image: ${({ theme }) => theme.gradient};
        }
        > video {
            :first-of-type {
                margin-bottom: 16px;
            }
        }
    }

    @media (min-width: 1024px) {
        justify-content: center;

        .insta-masonry-grid_column {
            padding-left: 32px;

            :first-of-type {
                padding-left: 0;
            }

            :last-of-type {
                padding-right: 0;
            }

            > video {
                :first-of-type {
                    margin-bottom: 32px;
                }
            }
        }
    }
`;

export const Instagram = () => {
    return (
        <InstagramSection>
            <h5>We are Social! @integrate_institute</h5>

            <Posts
                breakpointCols={{ default: 3, 1023: 6 }}
                className="insta-masonry-grid"
                columnClassName="insta-masonry-grid_column"
            >
                <video playsinline="playsinline" loop="loop" controls={true}>
                    <source src={video1} type="video/mp4" />
                </video>
                <video playsinline="playsinline" loop="loop" controls={true}>
                    <source src={video2} type="video/mp4" />
                </video>
            </Posts>

            <PrimarySmBtn
                as="a"
                href="https://www.instagram.com/integrate_institute/"
                target="_blank"
                rel="noreferrer"
                green
            >
                FOLLOW US ON INSTAGRAM
            </PrimarySmBtn>
        </InstagramSection>
    );
};
