import styled from '@emotion/styled';

const MapContainer = styled.div`
    position: relative;

    > iframe {
        border: 0;
        width: 100%;
        height: 600px;
        margin-bottom: -7px;
    }

    @media (min-width: 1280px) {
        width: 50%;
        max-width: 800px;

        > iframe {
            height: 100%;
        }
    }

    @media (min-width: 1600px) {
        > iframe {
            border-top-left-radius: 64px;
            overflow: hidden;
        }
    }
`;

const Cta = styled.h5`
    position: absolute;
    right: 0;
    top: 0;
    width: 104px;
    height: 103px;
    background: ${({ theme }) => theme.gradient};
    z-index: 10;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 24px;
    color: ${({ theme }) => theme.white};
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
`;

export const Map = () => {
    return (
        <MapContainer>
            <Cta>GET Directions</Cta>
            <iframe
                id="map"
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11353.01178066921!2d-93.2890481!3d44.6531835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f647d5204ca5b1%3A0x1d8df0326633ceb9!2sIntegrate%20Institute!5e0!3m2!1sen!2s!4v1643602877178!5m2!1sen!2s"
                allowFullScreen
                loading="lazy"
            ></iframe>
        </MapContainer>
    );
};
