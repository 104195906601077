import { PlainBtn } from '@/Buttons/PlainBtn';
import { Dialog } from '@/Dialog';
import { Dropdown } from '@/Dropdown';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { h4 } from './GlobalStyles';

type NavLinksProps = { text: string; link: string }[];

const navLinks: NavLinksProps = [
    {
        text: 'treatments',
        link: 'subMenu',
    },
    {
        text: 'conditions',
        link: 'subMenu',
    },
    {
        text: 'services',
        link: 'subMenu',
    },
    {
        text: 'about us',
        link: 'subMenu',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
];

interface SubNavLinksProps {
    [index: string]: NavLinksProps;
}

const subNavLinks: SubNavLinksProps = {
    treatments: [
        {
            text: 'chiropractic care',
            link: '/services/chiropractic-care/',
        },
        {
            text: 'Spinal Decompression',
            link: '/services/spinal-decompression/',
        },
        {
            text: 'Myofascial Release',
            link: '/services/myofascial-release/',
        },
        {
            text: 'Progressive Rehab',
            link: '/services/progressive-rehab/',
        },
    ],
    conditions: [
        {
            text: 'Neck pain',
            link: '/services/neck-pain/',
        },
        {
            text: 'back pain',
            link: '/services/back-pain/',
        },
        {
            text: 'shoulder pain',
            link: '/services/shoulder-pain/',
        },
        {
            text: 'carpal tunnel',
            link: '/services/carpal-tunnel/',
        },
        {
            text: 'herniated disc',
            link: '/services/herniated-disc/',
        },
        {
            text: 'knee pain',
            link: '/services/knee-pain/',
        },
        {
            text: 'hip pain',
            link: '/services/hip-pain/',
        },
        {
            text: 'scoliosis',
            link: '/services/scoliosis/',
        },
        {
            text: 'headaches',
            link: '/services/headaches/',
        },
    ],
    services: [
        {
            text: 'shockwave therapy',
            link: '/services/shockwave-therapy/',
        },
        {
            text: 'physical therapy',
            link: '/services/physical-therapy/',
        },
        {
            text: 'Sports Chiropractic',
            link: '/services/sports-chiropractic/',
        },
        {
            text: 'massage therapy',
            link: '/services/massage-therapy/',
        },
        {
            text: 'personal training',
            link: '/services/personal-training/',
        },
    ],
    'about us': [
        {
            text: 'about us',
            link: '/about-us/',
        },
        {
            text: 'FAQ',
            link: '/about-us/faq/',
        },
        {
            text: 'dr. alex twenge',
            link: '/about-us/dr-alex-twenge/',
        },
        {
            text: 'megan twenge',
            link: '/about-us/megan-twenge/',
        },
        {
            text: 'dr. derek fruetel',
            link: '/about-us/dr-derek-fruetel/',
        },
        {
            text: 'dr. gavin manning',
            link: '/about-us/dr-gavin-manning/',
        },
        {
            text: 'recommended products',
            link: '/about-us/recommended-products/',
        },
    ],
};

type NavLinkProps = {
    to: string;
};

const NavLink = styled.a<NavLinkProps>`
    color: ${({ theme }) => theme.white};
    font-weight: 700;
    text-transform: capitalize;
    position: relative;

    :hover,
    :focus {
        ::before {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            height: 6px;
            background: ${({ theme }) => theme.white};
            width: 81%;
            transform: translateX(-50%);
            border-radius: 8px;
            box-shadow: ${({ theme }) => theme.boxShadow1};
        }
    }
`;

export const SubNavLink = styled(Link)<NavLinkProps>`
    color: ${({ theme }) => theme.green};
    font-weight: 700;
    text-transform: capitalize;

    :hover,
    :focus {
        color: ${({ theme }) => theme.darkGreen};
    }
`;

export const NavLinkBtn = styled(PlainBtn)<{ expanded: string }>`
    position: relative;
    color: ${({ theme }) => theme.white};
    font-weight: 700;
    text-transform: capitalize;

    ::after {
        content: '';
        height: 8px;
        width: 8px;
        display: inline-block;
        border-left: 2px solid ${({ theme }) => theme.white};
        border-bottom: 2px solid ${({ theme }) => theme.white};
        transform: ${({ expanded }) =>
            expanded === 'true'
                ? 'rotate(135deg) translateY(0)'
                : 'rotate(-45deg) translateY(-3px)'};
        margin-left: 13px;
        margin-bottom: 2px;
    }

    :hover,
    :focus {
        ::before {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            height: 6px;
            background: ${({ theme }) => theme.white};
            width: 81%;
            transform: translateX(-50%);
            border-radius: 8px;
            box-shadow: ${({ theme }) => theme.boxShadow1};
        }
    }
`;

export const Burnsville = styled.a`
    ${h4};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.white};
    margin-right: 20px;
    transition: transform 0.15s ease-in-out;
    width: max-content;

    > img {
        margin-right: 8px;
    }

    :hover,
    :focus {
        transform: translateY(-3px);
    }
`;

export const MobileNavLinks = navLinks.map((navLink, i) =>
    navLink.link === 'subMenu' ? (
        <Dropdown key={i} text={navLink.text} links={subNavLinks[navLink.text]} />
    ) : (
        <NavLink as={Link} to={navLink.link} key={i}>
            {navLink.text}
        </NavLink>
    )
);

export const DesktopNavLinks = navLinks.map((navLink, i) =>
    navLink.link === 'subMenu' ? (
        <Dialog key={i} text={navLink.text} links={subNavLinks[navLink.text]} />
    ) : (
        <NavLink as={Link} to={navLink.link} key={i}>
            {navLink.text}
        </NavLink>
    )
);
