import facebook from '@a/layout/footer/facebook.svg';
import instagram from '@a/layout/footer/instagram.svg';
import docMedia from '@a/layout/footer/moderndocmedia.svg';
import youtube from '@a/layout/footer/youtube.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Fragment } from 'react';
import { h5 } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledFooter = styled.footer`
    background: ${({ theme }) => theme.black};
    padding: 16px;

    @media (min-width: 768px) {
        padding: 32px;
    }

    @media (min-width: 1366px) {
        padding: 64px;
    }
`;

const IconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
    padding: 32px;
    margin: 0 auto;

    > a {
        transition: transform 0.15s ease-in-out;

        :hover,
        :focus {
            transform: translateY(-5px);
        }
    }

    @media (min-width: 1366px) {
        padding: 64px;
    }
`;

const FooterInfo = styled.div`
    > div {
        max-width: 275px;
        margin-bottom: 32px;

        :last-of-type {
            margin-bottom: 0;
        }

        > h6 {
            ${h5};
            color: ${({ theme }) => theme.green};
            margin: 0 0 32px;
        }

        > p,
        > a {
            color: ${({ theme }) => theme.offWhite};
        }

        > a {
            display: block;
            margin: 16px 0;

            :hover,
            :focus {
                color: ${({ theme }) => theme.green};
            }
        }
    }

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-evenly;

        > div {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        margin: 0 auto;
        max-width: min(76vw, 1800px);
    }
`;

const Copyright = styled.div`
    border-top: 1px solid #cccccc;
    margin-top: 32px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > a,
    p {
        color: ${({ theme }) => theme.green};
    }

    > a {
        font-size: 0.75rem;
        letter-spacing: 0.25em;
        display: flex;
        align-items: center;

        > img {
            margin-right: 32px;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.offWhite};
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;
    }

    @media (min-width: 1366px) {
        max-width: min(85.5vw, 1920px);
        margin-left: auto;
        margin-right: auto;
    }
`;

export const Footer = () => {
    return (
        <Fragment>
            <IconBox>
                <a
                    href="https://www.facebook.com/IntegrateInstitute/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={facebook} alt="facebook" width={32} height={32} />
                </a>
                <a
                    href="https://www.instagram.com/integrate_institute/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={instagram} alt="instagram" width={30} height={30} />
                </a>
                <a
                    href="https://www.youtube.com/channel/UClRp3ULHquBEc2WfK53oY4A"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={youtube} alt="youtube" width={32} height={32} />
                </a>
            </IconBox>

            <StyledFooter>
                <Link
                    to="/"
                    css={css`
                        display: block;
                        width: max-content;
                        margin: 0 auto 64px;
                        transition: opacity 0.3s ease-in-out;

                        :hover,
                        :focus {
                            opacity: 0.75;
                        }
                    `}
                >
                    <StaticImage
                        src="../../../assets/images/layout/nav/logo.png"
                        alt="integrate institute"
                        width={352}
                        height={70}
                    />
                </Link>

                <FooterInfo>
                    <div>
                        <h6>Location</h6>
                        <a
                            href="https://g.page/Integrate_Institute?share"
                            target="_blank"
                            rel="noreferrer"
                        >
                            1103 West Burnsville Pkwy Suite 120 Burnsville, MN 55337, United States
                        </a>
                    </div>
                    <div>
                        <h6>Hours</h6>
                        <p>Mon - Thurs: 9am-12pm, 3pm-6pm</p>
                        <p>Fri & Sat: By appointment</p>
                        <p>Sun: Closed</p>
                    </div>
                    {/* <div>
                        <h6>Legal</h6>
                        <Link to="/legal/">Legal Information</Link>
                        <Link to="/privacy-policy/">Privacy Policy</Link>
                        <Link to="/terms/">Terms of Services</Link>
                    </div> */}
                </FooterInfo>

                <Copyright>
                    <p>Integrate Institute | All Rights Reserved. © {new Date().getFullYear()}</p>
                    <a href="https://moderndocmedia.com" target="_blank" rel="noreferrer">
                        <img src={docMedia} alt="logo" /> POWERED BY MODERN DOC MEDIA.
                    </a>
                </Copyright>
            </StyledFooter>
        </Fragment>
    );
};
