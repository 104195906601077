import { css, Global, Theme } from '@emotion/react';
import '@fontsource/raleway';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';
import '@fontsource/roboto';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import emotionNormalize from 'emotion-normalize';

export const theme: Theme = {
    white: '#FFFFFF',
    offWhite: '#EBEDEB',
    green: '#53993F',
    darkGreen: '#005550',
    darkGreenOverlay: 'rgba(0, 85, 80, 0.5)',
    black: '#1D1D1D',
    gradient: 'linear-gradient(119.63deg, #005550 0%, #53993F 100%);',
    boxShadow1: '0px 4px 16px #005550',
    boxShadow2: '0px 16px 32px 0 rgba(83, 153, 63, 0.16)',
    darkGreenFilter:
        'invert(50%) sepia(11%) saturate(2316%) hue-rotate(61deg) brightness(101%) contrast(86%);',
    spacing: {
        lg: '4.45vw', //64px at 1440px wide viewport
        xl: '6.67vw', //96px at 1440px wide viewport
        xxl: '8.89vw', //128px at 1440px wide viewport
    },
};

export const px = css`
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1366px) {
        padding-left: 64px;
        padding-right: 64px;
    }
`;

export const customScrollbar = css`
    overflow-x: auto;
    display: flex;
    align-items: flex-start;

    @media (pointer: fine) {
        &::-webkit-scrollbar {
            height: 6px;
        }
        &::-webkit-scrollbar-track {
            background: rgba(83, 155, 63, 0.15);
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(83, 155, 63, 0.25);
            border-radius: 30px;
        }
        // for firefox
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 85, 80, 0.3) rgba(0, 85, 80, 0.1);
    }
`;

export const customScrollbar2 = css`
    overflow-x: auto;
    display: flex;
    align-items: flex-start;

    @media (pointer: fine) {
        &::-webkit-scrollbar {
            height: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #ebedeb2b;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ebedeb45;
            border-radius: 30px;
        }
        // for firefox
        scrollbar-width: thin;
        scrollbar-color: #ebedeb78 #ebedeb2b;
    }
`;

export const h1 = css`
    font-size: 2.25rem;
    font-family: 'Raleway', sans-serif;
    line-height: 162%;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 2.5rem;
        line-height: 133%;
    }

    @media (min-width: 1440px) {
        font-size: 3rem;
    }
`;

export const h2 = css`
    font-size: 1.625rem;
    line-height: 162%;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }

    @media (min-width: 1440px) {
        font-size: 2.25rem;
    }
`;

export const h3 = css`
    font-size: 1.25rem;
    line-height: 162%;
    font-weight: 500;

    @media (min-width: 1280px) {
        font-size: 1.375rem;
    }

    @media (min-width: 1440px) {
        font-size: 1.5rem;
    }
`;

export const h4 = css`
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 122%;
`;

export const h5 = css`
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 137.5%;
`;

export default function GlobalStyles() {
    return (
        <Global
            styles={theme => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.black};
                    font-family: 'Roboto', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 150%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    font-size: 1.125rem;
                    line-height: 122%;
                }

                a {
                    text-decoration: none;
                    color: ${theme.black};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }
            `}
        />
    );
}
