import { PrimarySmBtn } from '@/Buttons/PrimarySmBtn';
import { css } from '@emotion/react';
import { Fragment } from 'react';
import styled from '@emotion/styled';
import { h1, h3 } from '@/layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const Availability = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 32px;

    h4 {
        ${h3};
        color: ${({ theme }) => theme.green};
        margin: 0 0 8px;
    }

    p {
        margin: 0 0 8px;

        :last-of-type {
            margin: 0;
        }
    }

    > div {
        margin-bottom: 32px;

        :last-of-type {
            margin-bottom: 0;
        }

        & > div {
            display: flex;

            > div:first-of-type {
                margin-right: 16px;
                font-weight: 700;
            }
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 64px;

        > div {
            margin: 0;
        }
    }
`;

const grayText = css`
    opacity: 50%;
`;

export const JillFisher = () => {
    return (
        <Fragment>
            <div className="service-article-intro">
                <StaticImage
                    src="../../assets/images/about-us/jill.jpg"
                    alt="jill fisher"
                    width={647}
                    height={724}
                />
                <div>
                    <h1
                        css={css`
                            margin-bottom: 0;
                        `}
                    >
                        Jill Fisher
                    </h1>
                    <h3
                        css={css`
                            margin: 0 0 32px;
                        `}
                    >
                        Massage Therapist
                    </h3>
                    <h3>&quot;The Greatest Wealth is Health &quot;</h3>
                    <p>
                        Jill Fisher has been a massage therapist for the last five years
                        specializing in deep tissue, myofascial release, trigger point therapy,
                        cupping, PNF stretching, and corrective exercise. Jill has also been a
                        personal trainer for the past fifteen years working with a variety of
                        patients helping individuals getting out of chronic pain, achieving health
                        and wellness, along with injury prevention.
                    </p>
                    <h3>SPECIALTIES: Bodywork and Massage</h3>

                    <li>Deep Tissue </li>
                    <li>Mobile Deep Tissue </li>
                    <li>Manual Therapy</li>

                    <PrimarySmBtn
                        as="a"
                        href="https://www.massagebook.com/Farmington~Massage~JMassage"
                        target="blank"
                        css={css`
                            margin: 32px 0;
                        `}
                        className="btn"
                    >
                        Book Jill Now
                    </PrimarySmBtn>
                </div>
            </div>

            <h3
                css={css`
                    ${h1};
                    margin: 0 0 32px;
                `}
            >
                Availability
            </h3>
            <Availability>
                <div>
                    <div>
                        <div>
                            <p>Monday:</p>
                            <p css={grayText}>Tuesday:</p>
                            <p>Wednesday:</p>
                            <p>Thursday:</p>
                            <p>Friday:</p>
                            <p>Saturday:</p>
                            <p css={grayText}>Sunday:</p>
                        </div>
                        <div>
                            <p>9:00 am - 3:00 pm</p>
                            <p css={grayText}>Closed</p>
                            <p>4:00 pm - 7:00 pm</p>
                            <p>5:30 pm - 8:00 pm</p>
                            <p>9:00 am - 12:30 pm</p>
                            <p>8:30 am - 2:00 pm</p>
                            <p css={grayText}>n/a</p>
                        </div>
                    </div>
                </div>
            </Availability>
        </Fragment>
    );
};
