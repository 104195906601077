module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Integrate Institute - Chiropractic Adjustment & Care","short_name":"Integrate Institute","start_url":"/","background_color":"#005550","theme_color":"#005550","display":"minimal-ui","icon":"assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b53ff6603f68947e9738d48d0a04e47c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://integrateinstitute.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-219744377-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/integrate-institute/integrate-institute/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.5.0","modulePath":"/home/runner/work/integrate-institute/integrate-institute/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/integrate-institute/integrate-institute/node_modules/gatsby-remark-images","id":"00cdf0e6-03c5-53da-b8d2-78b9190e9e73","name":"gatsby-remark-images","version":"6.6.0","modulePath":"/home/runner/work/integrate-institute/integrate-institute/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":647,"linkImagesToOriginal":false,"quality":100,"withWebp":{"quality":100},"tracedSVG":true,"loading":"eager","wrapperStyle":"overflow: hidden; border-radius: 24px; max-height: 724px; aspect-ratio: auto 647 / 724; margin-left: 0; margin-right: 0;"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/integrate-institute/integrate-institute","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":647,"linkImagesToOriginal":false,"quality":100,"withWebp":{"quality":100},"tracedSVG":true,"loading":"eager","wrapperStyle":"overflow: hidden; border-radius: 24px; max-height: 724px; aspect-ratio: auto 647 / 724; margin-left: 0; margin-right: 0;"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
