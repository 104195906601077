import { NavLinkBtn } from '@/layout/NavLinks';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItems, MenuLink, MenuPopover } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { Link } from 'gatsby';
import { Fragment, useEffect, useState } from 'react';

const StyledDropdown = styled.div<{ rows: number }>`
    position: relative;

    [data-reach-menu-popover] {
        position: absolute;
        top: 150%;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: column;
        align-items: center;
        transition: transform 0.3s ease, opacity 0.3s ease;

        &[hidden] {
            display: flex;

            &.notExpanded {
                display: none;
            }
            &.expanded {
                opacity: 0;
                transform: translateX(-50%) translateY(-10px);
            }
        }

        &:not([hidden]) {
            &.notExpanded {
                opacity: 0;
                transform: translateX(-50%) translateY(-10px);
                display: flex;
            }
            &.expanded {
                opacity: 1;
                transform: translateX(-50%) translateY(0);
            }
        }
    }

    [data-reach-menu-items] {
        background: ${({ theme }) => theme.white};
        padding: 32px;
        border-radius: 16px;
        box-shadow: 0px -16px 32px rgba(255, 255, 255, 0.25), 0px 16px 64px rgba(0, 85, 80, 0.2);
        display: grid;
        grid-template-rows: ${({ rows }) => `repeat(${rows}, 1fr)`};
        grid-auto-flow: column;
        gap: 16px 32px;
    }

    [data-reach-menu-item] {
        color: ${({ theme }) => theme.green};
        font-weight: 700;
        text-transform: capitalize;
        padding: 0;

        &[data-selected] {
            color: ${({ theme }) => theme.darkGreen};
            background: none;
        }
    }
`;

type DropdownProps = {
    text?: string;
    links: {
        text: string;
        link: string;
    }[];
    isExpanded?: boolean;
};

const StyledPopover = ({ isExpanded, links }: DropdownProps) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isExpanded) {
            timeoutId = setTimeout(() => setExpanded(true), 100);
        } else if (!isExpanded) {
            timeoutId = setTimeout(() => setExpanded(false), 300);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isExpanded]);

    return (
        <MenuPopover portal={false} className={expanded ? 'expanded' : 'notExpanded'}>
            <MenuItems tabIndex={0}>
                {links.map((link, i) => (
                    <MenuLink as={Link} key={i} to={link.link}>
                        {link.text}
                    </MenuLink>
                ))}
            </MenuItems>
        </MenuPopover>
    );
};

export const Dropdown = ({ text, links }: DropdownProps) => {
    return (
        <StyledDropdown rows={links.length > 5 && text !== 'about us' ? 5 : links.length}>
            <Menu>
                {({ isExpanded }) => (
                    <Fragment>
                        <NavLinkBtn as={MenuButton} expanded={isExpanded ? 'true' : 'false'}>
                            {text}
                        </NavLinkBtn>
                        <StyledPopover links={links} isExpanded={isExpanded} />
                    </Fragment>
                )}
            </Menu>
        </StyledDropdown>
    );
};
