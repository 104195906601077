import lgArrow from '@a/lg-arrow.svg';
import smArrow from '@a/sm-arrow.svg';
import styled from '@emotion/styled';
import { PlainBtn } from './PlainBtn';

export const SecondaryBtn = styled(PlainBtn)`
    color: ${({ theme }) => theme.darkGreen};
    padding-right: 48px;
    padding-left: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-right: 16px;
    transition: padding-left 0.3s ease-in-out, margin-right 0.3s ease-in-out;
    position: relative;

    ::after {
        content: '';
        position: absolute;
        top: calc(1em / 2);
        right: 16px;
        width: 5px;
        height: 10px;
        background: url(${smArrow}) no-repeat;
        background-position: center right;
        animation-timing-function: ease-in-out;
        animation-duration: 0.3s;
    }

    :hover,
    :focus {
        padding-left: 16px;
        margin-right: 0;

        ::after {
            background-image: url(${lgArrow});
            width: 14px;
            transform-origin: right;
            animation-name: growFromRight;
        }

        @keyframes growFromRight {
            0% {
                transform: scaleX(0.6);
            }
            100% {
                transform: scaleX(1);
            }
        }
    }
`;
