import { PrimarySmBtn } from '@/Buttons/PrimarySmBtn';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

export const JakeMorphis = () => {
    return (
        <Fragment>
            <div className="service-article-intro">
                <StaticImage
                    src="../../assets/images/about-us/jake.jpg"
                    alt="jake morphis"
                    width={504}
                    height={568}
                />
                <div>
                    <h1
                        css={css`
                            margin-bottom: 0;
                        `}
                    >
                        Jake Morphis
                    </h1>
                    <h3
                        css={css`
                            margin: 0 0 32px;
                        `}
                    >
                        Strength and Conditioning Specialist | Morph Performance
                    </h3>
                    <p>
                        As a trainer and coach, Jake is passionate about helping others reach their
                        fitness goals whether they are competition based or for overall health and
                        well-being. Wanting to learn more about body mechanics and functionality, he
                        graduated from the University of Minnesota with a bachelor’s degree in
                        Kinesiology. As a certified Strength and Conditioning Specialist, he
                        believes that fitness comes from building a base understanding of functional
                        mobility and movement.
                    </p>

                    <p>
                        This base becomes the foundation for meeting and exceeding goals for
                        individuals of all ages and ability levels. He believes the quality of body
                        mechanics and exercise is always more important than the quantity of
                        exercise you do and that is evident in his training. Outside of working out,
                        volunteering or competing in powerlifting competitions, he is a huge
                        football fan who enjoys trying new things in the outdoors and having game
                        nights with friends and family.
                    </p>
                </div>
            </div>
            <h1
                css={css`
                    margin-bottom: 0;
                `}
            >
                New Clients
            </h1>
            <h3
                css={css`
                    margin: 0;
                `}
            >
                Scan the code or Click the Button below to get connected.
            </h3>

            <PrimarySmBtn
                as="a"
                href="https://calendly.com/d/zgx-cxg-7ft"
                target="blank"
                css={css`
                    margin: 32px 0;
                `}
                className="btn"
            >
                get appointment
            </PrimarySmBtn>
            <br />
            <StaticImage
                src="../../assets/images/about-us/jake-qr.png"
                alt="qr code for booking with jake"
                width={343}
                height={343}
            />
        </Fragment>
    );
};
