import React from 'react';
import { A11y, Keyboard, Navigation } from 'swiper';
// eslint-disable-next-line
import 'swiper/css';
// eslint-disable-next-line
import 'swiper/css/navigation';
// eslint-disable-next-line
import { Swiper } from 'swiper/react';

type SliderProps = {
    children: React.ReactNode;
    sliderSettings: object;
};

export default function Slider({ children, sliderSettings }: SliderProps) {
    return (
        <Swiper
            modules={[Navigation, Keyboard, A11y]}
            centerInsufficientSlides
            watchOverflow
            {...sliderSettings}
        >
            {children}
        </Swiper>
    );
}
