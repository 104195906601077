import { PlainBtn } from '@/Buttons/PlainBtn';
import { h1 } from '@/layout/GlobalStyles';
import Slider from '@/Slider';
import rightArrow from '@a/right-arrow.svg';
import star from '@a/star.svg';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// eslint-disable-next-line
import { SwiperSlide } from 'swiper/react';

const TestimonialSection = styled.section`
    text-align: center;
    padding: 32px 0 8px;

    > p {
        font-weight: 700;
        letter-spacing: 0.1em;

        :first-of-type {
            color: ${({ theme }) => theme.green};
            text-transform: uppercase;
            margin: 0;
        }

        :last-of-type {
            color: ${({ theme }) => theme.darkGreen};
            margin: 16px 0 32px;
        }
    }

    > h5 {
        ${h1};
        color: ${({ theme }) => theme.darkGreen};
        margin: 32px 8px;
    }

    @media (min-width: 1024px) {
        padding: 64px 0 48px;
    }
`;

const SingleSlide = styled.div`
    margin: 48px auto 24px;
    border-radius: 16px;
    padding: 64px 16px 48px;
    width: 84%;
    max-width: 600px;
    position: relative;
    transition: box-shadow 0.15s ease-in-out;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
        width: 100%;
        display: flex;
    }

    @media (min-width: 1024px) {
        padding: 88px 24px 64px;
        margin-left: 8px;
        margin-right: 8px;
    }
`;

const SliderContainer = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 0 8px;

    /* this way hidden slide box shadow does'nt spill over from edges */
    .swiper-slide-visible {
        ${SingleSlide} {
            box-shadow: 0px 20px 30px rgba(126, 126, 126, 0.15);

            :hover {
                box-shadow: ${({ theme }) => theme.boxShadow2};
            }
        }
    }

    @media (min-width: 1024px) {
        padding: 0 64px;
        width: 95.5vw;
        max-width: 1730px;

        .swiper-slide {
            display: flex;
            height: auto;
        }
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    position: absolute;
    top: -44px;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ theme }) => theme.offWhite};
    overflow: hidden;
`;

const Testimonial = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > p {
        text-align: left;
        margin: 0 0 16px;
    }

    > div {
        margin-top: auto;

        > p {
            margin: 0 0 16px;
            font-weight: 700;
            font-family: 'Raleway', sans-serif;
            text-transform: capitalize;
        }

        > img {
            margin-right: 2px;
            display: inline;

            :last-of-type {
                margin-right: 0;
            }
        }
    }

    @media (min-width: 640px) {
        > p {
            text-align: center;
        }
    }
`;

const NavigationBtn = styled(PlainBtn)`
    width: 56px;
    height: 56px;
    position: absolute;
    bottom: 48px;
    left: 8px;
    z-index: 50;
    background: ${({ theme }) => theme.gradient};
    border-radius: 8px;
    transform: none;
    transition: transform 0.3s ease-in-out;

    :hover,
    :focus {
        background: ${({ theme }) => theme.darkGreen};
        transform: translateY(-8px);
    }

    &.testimonial-slider-disabled {
        opacity: 0.5;

        :hover,
        :focus {
            background: ${({ theme }) => theme.gradient};
            transform: none;
        }
    }

    &.swiper-button-lock {
        display: none;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        transform: scaleX(-1);
        background-image: url(${rightArrow});
        background-position: center;
        background-repeat: no-repeat;
    }

    & + button {
        left: unset;
        right: 8px;

        &::before {
            transform: scaleX(1);
        }
    }

    @media (min-width: 640px) {
        bottom: 49%;
        transform: translateY(50%);

        :hover,
        :focus {
            background: ${({ theme }) => theme.darkGreen};
            transform: translateY(calc(50% + -8px));
        }
        
        &.testimonial-slider-disabled {
            :hover,
            :focus {
                transform: translateY(50%);
            }
        }
    }

    @media (min-width: 790px) and (max-width: 1023px) {
        left: calc(((100vw - 600px) / 2) - 96px);

        & + button {
            right: calc(((100vw - 600px) / 2) - 96px);
        }
    }

    @media (min-width: 1024px) {
        left: 0;

        & + button {
            left: unset;
            right: 0;
        }
    }
`;

const users = [
    {
        text: 'Alex and Megan are so incredible and knowledgeable. Their insight is invaluable! I felt remarkably better after just one visit. They are seriously the best at what they do!!! I can’t recommend this place enough.',
        name: 'maddie jensen',
    },
    {
        text: 'Helped me get out of the woods of a very stiff neck and shoulder within two visits. 😀 The team members there are all kind, personable, and taught me a lot!      ',
        name: 'Amelia Chapman',
    },
    {
        text: 'I have been seeing chiropractors for 20 years now and Dr. Alex along with seeing PT Megan is hands down the best experience I have had. As athletes themselves, they really understand the demands training can place on the body and they work together to get you moving correctly to fix injuries and prevent future issues....',
        name: 'Shannon Murphy',
    },
    {
        text: 'Great staff, great knowledge. Knew my issue and had ways to help right away!',
        name: 'Alex martinson',
    },
    {
        text: 'They are skilled and compassionate. I arrived in quite a bit of pain with no lasting relief from other PT and Chiros. Dr. Alex and Megan are very supportive and knowledgeable, creating a trusting environment focused on a long term individualized healing plan. After only a few sessions and at home simple exercises, my pain diminished significantly... I highly recommend them both!',
        name: 'nicole milo',
    },
    {
        text: "Alex and Megan are wonderful! Came in for knee pain. Came out of my sessions with no knee pain and the tools to  maintain it for years to come so it doesn't come back! They both are awesome to work with and so knowledgeable! Would recommend them to anyone!",
        name: 'penny kaiser',
    },
    {
        text: 'I’ve been to a dozen different chiropractors over the years and for the most part enjoyed my (perhaps 150) visits, but always found that the next day I felt like I needed to go back ...it left me with a love/hate relationship with chiropractors.',
        name: 'Trent Emisner',
    },
];

export const Testimonials = () => {
    const {
        allFile: { nodes },
    } = useStaticQuery(graphql`
        query testimonialImages {
            allFile(
                filter: { relativeDirectory: { eq: "layout/testimonials" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 88, height: 88, layout: FIXED)
                    }
                }
            }
        }
    `);

    const testimonials = users.map((t, i) => ({
        img: getImage(nodes[i]),
        ...t,
    }));

    const sliderSettings = {
        navigation: {
            nextEl: '.testimonial-slider-next',
            prevEl: '.testimonial-slider-prev',
            disabledClass: 'testimonial-slider-disabled',
        },
        autoHeight: true,
        spaceBetween: 16,
        slidesPerView: 1,
        watchSlidesProgress: true,
        breakpoints: {
            1024: {
                autoHeight: false,
                slidesPerView: 2,
                spaceBetween: 0,
            },

            1366: {
                autoHeight: false,
                spaceBetween: 0,
                slidesPerView: 3,
            },
        },
    };

    return (
        <TestimonialSection>
            <p>Testimonials</p>
            <h5>Be our next success story</h5>
            <p>5 star reviews</p>
            <SliderContainer>
                <Slider sliderSettings={sliderSettings}>
                    {testimonials.map((testimonial, i) => (
                        <SwiperSlide key={i}>
                            <SingleSlide>
                                <ImageWrapper>
                                    {testimonial.img ? (
                                        <GatsbyImage
                                            image={testimonial.img}
                                            alt={testimonial.name}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </ImageWrapper>
                                <Testimonial>
                                    <p>{testimonial.text}</p>
                                    <div>
                                        <p>{testimonial.name}</p>
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <img src={star} alt="5 star review" key={i} />
                                        ))}
                                    </div>
                                    <a href="/contact-us">contact</a>
                                </Testimonial>
                            </SingleSlide>
                        </SwiperSlide>
                    ))}
                </Slider>
                <NavigationBtn className="testimonial-slider-prev" />
                <NavigationBtn className="testimonial-slider-next" />
            </SliderContainer>
        </TestimonialSection>
    );
};
