import { createContext, FC, useContext } from 'react';

type SiteContext = {
    theme: string;
};

export const siteContext = createContext<SiteContext>({
    theme: 'default',
});

export const useLayoutContext = (): SiteContext => useContext(siteContext);

export const SiteContextProvider: FC = ({ children }) => {
    return <siteContext.Provider value={{ theme: 'default' }}>{children}</siteContext.Provider>;
};
