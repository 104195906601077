import styled from '@emotion/styled';
import { SecondaryBtn } from './SecondaryBtn';

export const PrimarySmBtn = styled(SecondaryBtn)<{ green?: boolean; gradient?: boolean }>`
    color: ${({ theme }) => theme.white};
    background: ${({ theme, green, gradient }) =>
        green ? theme.green : gradient ? theme.gradient : theme.darkGreen};
    padding: 8px 48px 8px 16px;
    border-radius: 8px;
    margin-right: 0;

    ::after {
        filter: brightness(0) invert(1);
        top: calc((1em / 2) + 8px);
        animation-duration: 0.15s;
    }
`;
