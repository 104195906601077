import { BookingForm } from '@/BookingForm';
import { Map } from '@/Map';
import styled from '@emotion/styled';

const MapSection = styled.section`
    @media (min-width: 1280px) {
        display: flex;
        justify-content: center;
    }
`;

export const MapBookingForm = () => {
    return (
        <MapSection>
            <Map />
            <BookingForm />
        </MapSection>
    );
};
